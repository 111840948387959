import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Account = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 16 16" className={twMerge('stroke-none', className)} {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.00033 10.6673C4.89576 10.6673 4.00033 11.5627 4.00033 12.6673C4.00033 13.0355 4.2988 13.334 4.66699 13.334H11.3337C11.7018 13.334 12.0003 13.0355 12.0003 12.6673C12.0003 11.5627 11.1049 10.6673 10.0003 10.6673H6.00033ZM2.66699 12.6673C2.66699 10.8264 4.15938 9.33398 6.00033 9.33398H10.0003C11.8413 9.33398 13.3337 10.8264 13.3337 12.6673C13.3337 13.7719 12.4382 14.6673 11.3337 14.6673H4.66699C3.56242 14.6673 2.66699 13.7719 2.66699 12.6673Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00033 2.66732C6.89576 2.66732 6.00033 3.56275 6.00033 4.66732C6.00033 5.77189 6.89576 6.66732 8.00033 6.66732C9.10489 6.66732 10.0003 5.77189 10.0003 4.66732C10.0003 3.56275 9.10489 2.66732 8.00033 2.66732ZM4.66699 4.66732C4.66699 2.82637 6.15938 1.33398 8.00033 1.33398C9.84127 1.33398 11.3337 2.82637 11.3337 4.66732C11.3337 6.50827 9.84127 8.00065 8.00033 8.00065C6.15938 8.00065 4.66699 6.50827 4.66699 4.66732Z" />
  </Svg>

);

export default Account;
