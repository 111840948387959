import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Site = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 24" className={twMerge('stroke-none', className)} {...rest}>
    <path
      opacity="0.25"
      d="M20 18.5H4L6 6.5H18L20 18.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.5C19.1046 8.5 20 7.60457 20 6.5C20 5.39543 19.1046 4.5 18 4.5C16.8954 4.5 16 5.39543 16 6.5C16 7.60457 16.8954 8.5 18 8.5ZM20 20.5C21.1046 20.5 22 19.6046 22 18.5C22 17.3954 21.1046 16.5 20 16.5C18.8954 16.5 18 17.3954 18 18.5C18 19.6046 18.8954 20.5 20 20.5ZM6 18.5C6 19.6046 5.10457 20.5 4 20.5C2.89543 20.5 2 19.6046 2 18.5C2 17.3954 2.89543 16.5 4 16.5C5.10457 16.5 6 17.3954 6 18.5ZM6 8.5C7.10457 8.5 8 7.60457 8 6.5C8 5.39543 7.10457 4.5 6 4.5C4.89543 4.5 4 5.39543 4 6.5C4 7.60457 4.89543 8.5 6 8.5Z"
    />
  </Svg>
);

export default Site;
