import React from 'react';
import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Mapping = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.01051 20.9998C2.82041 20.9998 2.63032 20.9398 2.48024 20.8499C2.18009 20.66 2 20.3403 2.01 20.0005V7.00967H2C2 6.62994 2.2101 6.28019 2.55028 6.1203L8.51655 3.14075C8.52863 3.13397 8.54088 3.12744 8.55328 3.12115V3.11242C8.83342 2.96253 9.16358 2.96253 9.45373 3.11242L15.0065 5.88047L20.5593 3.11242C21.0495 2.8626 21.6498 3.05246 21.8999 3.55211C21.96 3.68202 22 3.84191 22 3.9918V16.9826C21.99 17.3524 21.7799 17.7021 21.4497 17.872L15.6619 20.7624C15.4873 20.9104 15.2617 21 15.0161 21C14.7947 21 14.592 20.9298 14.4278 20.8108L8.9935 18.1018L3.44072 20.8699C3.30065 20.9398 3.14057 20.9698 2.9905 20.9798L3.01051 20.9998ZM16.0166 18.3808L20.019 16.3821V5.62065L16.0166 7.61504V18.3808ZM14.0156 7.61455V18.3814L10.0138 16.3864V5.61963L14.0156 7.61455ZM4.01101 7.61924L8.01277 5.62077V16.3776L4.01101 18.3717V7.61924Z"
    />
  </Svg>
);

export default Mapping;
