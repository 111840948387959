import Svg, { SvgProps } from './Svg';

const Suspended = ({ className, ...rest }: SvgProps) => (
  <Svg
    className="fill-none stroke-none"
    viewBox="0 0 14 14"
    {...rest}
  >
    <path className="fill-blue-100" fillRule="evenodd" clipRule="evenodd" d="M13.6654 6.9987C13.6654 10.6806 10.6806 13.6654 6.9987 13.6654C3.3168 13.6654 0.332031 10.6806 0.332031 6.9987C0.332031 3.3168 3.3168 0.332031 6.9987 0.332031C10.6806 0.332031 13.6654 3.3168 13.6654 6.9987ZM10.2692 11.2119C9.36602 11.914 8.23118 12.332 6.9987 12.332C4.05318 12.332 1.66536 9.94422 1.66536 6.9987C1.66536 5.76621 2.08343 4.63137 2.78548 3.72825L10.2692 11.2119ZM11.212 10.2691L3.72829 2.78544C4.63141 2.08341 5.76623 1.66536 6.9987 1.66536C9.94422 1.66536 12.332 4.05318 12.332 6.9987C12.332 8.23116 11.914 9.36599 11.212 10.2691Z" />
  </Svg>
);

export default Suspended;
