import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const ShutEye = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.29289 2.29289C2.65338 1.93241 3.22061 1.90468 3.6129 2.2097L3.70711 2.29289L17.9832 16.569C18.0385 16.6134 18.0897 16.6643 18.1356 16.7214L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3466 22.0676 20.7794 22.0953 20.3871 21.7903L20.2929 21.7071L17.206 18.6203C15.6021 19.5382 13.8623 20 12 20C7.6044 20 3.96348 17.4514 1.13177 12.4962C0.956057 12.1887 0.956079 11.8112 1.13182 11.5037C2.32272 9.42032 3.65271 7.76402 5.12709 6.5413L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289ZM6.54861 7.96282C5.40937 8.87487 4.34586 10.11 3.3617 11.6732L3.161 12L3.27859 12.1941C5.63002 15.9935 8.42551 17.8901 11.709 17.9954L12 18C13.326 18 14.5644 17.7155 15.7281 17.1423L13.2947 14.7089C12.1786 15.2434 10.8005 15.0479 9.8761 14.1229C8.95222 13.1984 8.75747 11.8212 9.29159 10.7058L6.54861 7.96282ZM11.2385 12.6527L11.3478 12.762C11.3283 12.7453 11.3093 12.7277 11.2908 12.7092C11.2725 12.6909 11.2551 12.672 11.2385 12.6527ZM11.9996 3.99994C16.3951 3.99994 20.0361 6.54859 22.8678 11.5038C23.0435 11.8113 23.0435 12.1888 22.8677 12.4963C22.0529 13.9217 21.1749 15.1455 20.2309 16.1668C19.8561 16.5723 19.2234 16.5972 18.8178 16.2224C18.4122 15.8475 18.3873 15.2148 18.7622 14.8092C19.4125 14.1056 20.0351 13.283 20.6292 12.3407L20.8386 12L20.721 11.8059C18.3695 8.00649 15.5739 6.10986 12.2873 6.00458L11.9961 5.99993C11.1984 5.99716 10.4044 6.10708 9.6376 6.32643C9.10661 6.47833 8.55302 6.17102 8.40113 5.64003C8.24923 5.10905 8.55654 4.55546 9.08753 4.40357C10.0355 4.1324 11.017 3.99652 11.9996 3.99994Z" />
  </Svg>
);

export default ShutEye;
