import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Plus = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 16 16" className={twMerge('stroke-none', className)} {...rest}>
    <g id="Math / Plus">
      <path id="Style" fillRule="evenodd" clipRule="evenodd" d="M8.66226 3.25558C8.62375 2.92402 8.34197 2.66666 8.00008 2.66666C7.63189 2.66666 7.33342 2.96513 7.33342 3.33332V7.33332H3.33341L3.25567 7.33781C2.92411 7.37632 2.66675 7.6581 2.66675 7.99999C2.66675 8.36818 2.96522 8.66666 3.33341 8.66666H7.33342V12.6667L7.3379 12.7444C7.37641 13.076 7.65819 13.3333 8.00008 13.3333C8.36827 13.3333 8.66675 13.0348 8.66675 12.6667V8.66666H12.6667L12.7445 8.66217C13.0761 8.62366 13.3334 8.34188 13.3334 7.99999C13.3334 7.6318 13.0349 7.33332 12.6667 7.33332H8.66675V3.33332L8.66226 3.25558Z" fill="#002B54" />
    </g>
  </Svg>
);

export default Plus;
