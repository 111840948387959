import { twMerge } from 'tailwind-merge';
import React from 'react';
import Svg, { SvgProps } from './Svg';

const QuestionMarkSearch = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 16 16" className={twMerge('stroke-none', className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 1.33398C3.72115 1.33398 1.33333 3.7218 1.33333 6.66732C1.33333 9.61284 3.72115 12.0007 6.66667 12.0007C7.89914 12.0007 9.03397 11.5826 9.93709 10.8806L13.5286 14.4721L13.5914 14.5275C13.8529 14.7309 14.2311 14.7124 14.4714 14.4721C14.7318 14.2117 14.7318 13.7896 14.4714 13.5292L10.8799 9.93775C11.5819 9.03462 12 7.89979 12 6.66732C12 3.7218 9.61218 1.33398 6.66667 1.33398ZM6.66667 2.66732C8.8758 2.66732 10.6667 4.45818 10.6667 6.66732C10.6667 8.87646 8.8758 10.6673 6.66667 10.6673C4.45753 10.6673 2.66667 8.87646 2.66667 6.66732C2.66667 4.45818 4.45753 2.66732 6.66667 2.66732ZM6.66667 8.00065C7.00856 8.00065 7.29034 8.25801 7.32885 8.58957L7.33333 8.67398C7.33333 9.04217 7.03486 9.34065 6.66667 9.34065C6.32477 9.34065 6.04299 9.08329 6.00448 8.75173L6 8.66732C6 8.29913 6.29848 8.00065 6.66667 8.00065ZM7.42392 4.18236C6.73721 3.83241 5.90013 3.99895 5.39966 4.5851C5.16058 4.86511 5.19377 5.28591 5.47378 5.52499C5.75379 5.76406 6.17459 5.73088 6.41367 5.45087L6.45417 5.41088C6.55515 5.32693 6.69835 5.30908 6.81852 5.37033C6.95586 5.44032 7.0273 5.59535 6.99127 5.74523C6.95524 5.8951 6.82115 6.00073 6.667 6.00065C6.29881 6.00047 6.00018 6.29879 6 6.66698C5.99981 7.03517 6.29814 7.3338 6.66633 7.33398C7.43707 7.33437 8.10754 6.80624 8.28767 6.05686C8.46781 5.30747 8.11063 4.53231 7.42392 4.18236Z" fill="#002B54" />
  </Svg>
);

export default QuestionMarkSearch;
