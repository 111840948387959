import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const NoResults = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 117 121" className={twMerge('stroke-none', className)} {...rest}>
    <g clipPath="url(#clip0_1_2)">
      <path d="M40.568 15.0232C40.568 15.0232 52.2327 24.9053 70.6489 8.4576C87.0043 -6.14984 99.5579 18.8329 99.3967 30.4851C99.1879 45.5795 82.7864 56.8376 90.4038 67.143C98.0212 77.4483 74.3006 92.6339 62.0152 77.836C46.7327 59.4276 42.1783 73.5278 33.2493 73.0773C26.8409 72.7539 14.0396 55.6737 23.1898 43.9091C30.8893 34.0093 26.8858 30.3927 24.9882 26.73C22.2515 21.4477 29.3186 7.84159 40.568 15.0232Z" fill="#EBF9F8" />
      <g filter="url(#filter0_d_1_2)">
        <path d="M30 20.7796C30 17.5876 32.5884 15 35.7814 15H69.2186C72.4116 15 75 17.5876 75 20.7796V65.2204C75 68.4124 72.4116 71 69.2186 71H35.7814C32.5884 71 30 68.4124 30 65.2204V20.7796Z" fill="url(#paint0_linear_1_2)" />
      </g>
      <path d="M34 23.7413C34 22.7796 34.7898 22 35.764 22H50.236C51.2102 22 52 22.7796 52 23.7413C52 24.703 51.2102 25.4826 50.236 25.4826H35.764C34.7898 25.4826 34 24.703 34 23.7413Z" fill="#B3A9C0" />
      <path d="M34 33.6207C34 32.659 34.7898 31.8793 35.764 31.8793H68.236C69.2102 31.8793 70 32.659 70 33.6207C70 34.5824 69.2102 35.362 68.236 35.362H35.764C34.7898 35.362 34 34.5824 34 33.6207Z" fill="#E6E2EA" />
      <path d="M34 43.5C34 42.5383 34.7898 41.7587 35.764 41.7587H68.236C69.2102 41.7587 70 42.5383 70 43.5C70 44.4617 69.2102 45.2413 68.236 45.2413H35.764C34.7898 45.2413 34 44.4617 34 43.5Z" fill="#E6E2EA" />
      <path d="M34 53.3793C34 52.4176 34.7898 51.638 35.764 51.638H68.236C69.2102 51.638 70 52.4176 70 53.3793C70 54.341 69.2102 55.1207 68.236 55.1207H35.764C34.7898 55.1207 34 54.341 34 53.3793Z" fill="#E6E2EA" />
      <path d="M34 63.2587C34 62.297 34.7898 61.5174 35.764 61.5174H68.236C69.2102 61.5174 70 62.297 70 63.2587C70 64.2204 69.2102 65 68.236 65H35.764C34.7898 65 34 64.2204 34 63.2587Z" fill="#E6E2EA" />
      <g filter="url(#filter1_d_1_2)">
        <path d="M95.5604 9H73.8332C72.8208 9 72 9.86377 72 10.9293V21.6768C72 22.7423 72.8208 23.606 73.8332 23.606H95.5604C96.5729 23.606 97.3936 22.7423 97.3936 21.6768V10.9293C97.3936 9.86377 96.5729 9 95.5604 9Z" fill="white" />
      </g>
      <path d="M78.1698 18.3396C79.3682 18.3396 80.3396 17.3682 80.3396 16.1698C80.3396 14.9715 79.3682 14 78.1698 14C76.9715 14 76 14.9715 76 16.1698C76 17.3682 76.9715 18.3396 78.1698 18.3396Z" fill="#00D26E" />
      <path d="M83.2327 16.1698C83.2327 14.9715 84.2041 14 85.4025 14H92.6352C93.8335 14 94.805 14.9715 94.805 16.1698C94.805 17.3682 93.8335 18.3396 92.6352 18.3396H85.4025C84.2041 18.3396 83.2327 17.3682 83.2327 16.1698Z" fill="#00D26E" />
      <path d="M90.5341 64.5872L79.5078 53.791L77.3244 55.9805L88.3507 66.7767L90.5341 64.5872Z" fill="#B3A9C0" />
      <path d="M94.7463 67.4881L86.1678 59.0886C86.12 59.0418 86.043 59.0423 85.9958 59.0896L82.7489 62.3456C82.7018 62.3929 82.7022 62.4692 82.75 62.5159L91.3285 70.9154C91.3762 70.9622 91.4532 70.9617 91.5004 70.9144L94.7473 67.6585C94.7945 67.6111 94.794 67.5348 94.7463 67.4881Z" fill="#807096" />
      <path fillRule="evenodd" clipRule="evenodd" d="M83.4705 45.6101C83.4705 53.1268 77.321 59.2203 69.7352 59.2203C62.1495 59.2203 56 53.1268 56 45.6101C56 38.0935 62.1495 32 69.7352 32C77.321 32 83.4705 38.0935 83.4705 45.6101ZM81.2294 45.6103C81.2294 51.9005 76.0833 56.9997 69.7353 56.9997C63.3873 56.9997 58.2412 51.9005 58.2412 45.6103C58.2412 39.3202 63.3873 34.2209 69.7353 34.2209C76.0833 34.2209 81.2294 39.3202 81.2294 45.6103Z" fill="#807096" />
      <path opacity="0.6" d="M69.7353 56.9995C76.0833 56.9995 81.2294 51.9003 81.2294 45.6101C81.2294 39.3199 76.0833 34.2207 69.7353 34.2207C63.3873 34.2207 58.2412 39.3199 58.2412 45.6101C58.2412 51.9003 63.3873 56.9995 69.7353 56.9995Z" fill="#E6E2EA" />
      <path d="M71.9847 46L74.6224 43.3724C74.8696 43.1125 75.005 42.767 74.9999 42.4094C74.9948 42.0518 74.8496 41.7103 74.5951 41.4574C74.3407 41.2045 73.9971 41.0602 73.6373 41.0552C73.2775 41.0501 72.93 41.1847 72.6684 41.4303L70.0247 44.0579L67.387 41.4303C67.26 41.2968 67.1074 41.1899 66.9382 41.116C66.769 41.0421 66.5866 41.0027 66.4018 41.0001C66.217 40.9975 66.0336 41.0318 65.8623 41.1009C65.6911 41.17 65.5355 41.2725 65.4049 41.4024C65.2742 41.5323 65.171 41.6869 65.1015 41.8571C65.032 42.0273 64.9975 42.2096 65.0001 42.3933C65.0028 42.577 65.0424 42.7583 65.1167 42.9264C65.1911 43.0946 65.2986 43.2463 65.4329 43.3724L68.0751 46L65.4329 48.6276C65.2986 48.7537 65.1911 48.9054 65.1167 49.0736C65.0424 49.2417 65.0028 49.423 65.0001 49.6067C64.9975 49.7904 65.032 49.9727 65.1015 50.1429C65.171 50.3131 65.2742 50.4677 65.4049 50.5976C65.5355 50.7275 65.6911 50.83 65.8623 50.8991C66.0336 50.9682 66.217 51.0025 66.4018 50.9999C66.5866 50.9973 66.769 50.9579 66.9382 50.884C67.1074 50.8101 67.26 50.7032 67.387 50.5697L70.0306 47.9421L72.6743 50.5697C72.9383 50.8008 73.2811 50.9232 73.6327 50.9119C73.9844 50.9005 74.3184 50.7564 74.5668 50.5087C74.8152 50.2611 74.9593 49.9286 74.9696 49.5791C74.9799 49.2296 74.8558 48.8893 74.6224 48.6276L71.9847 46Z" fill="#7966EA" />
    </g>
    <defs>
      <filter id="filter0_d_1_2" x="0" y="5" width="105" height="116" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.501961 0 0 0 0 0.439216 0 0 0 0 0.588235 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2" result="shape" />
      </filter>
      <filter id="filter1_d_1_2" x="65" y="0" width="51.3936" height="40.606" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="6" dy="4" />
        <feGaussianBlur stdDeviation="6.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.823529 0 0 0 0 0.431373 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_1_2" x1="52.5" y1="15" x2="52.5" y2="71" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.71875" stopColor="#FAFAFA" />
      </linearGradient>
      <clipPath id="clip0_1_2">
        <rect width="117" height="121" fill="white" />
      </clipPath>
    </defs>
  </Svg>

);

export default NoResults;
