import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Padlock = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9954 6.78311C16.8818 4.12231 14.6888 2 12 2C9.23858 2 7 4.23858 7 7V10C5.34315 10 4 11.3431 4 13V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V13C20 11.3431 18.6569 10 17 10V7L16.9954 6.78311ZM15 10V7C15 5.34315 13.6569 4 12 4C10.4023 4 9.09634 5.24892 9.00509 6.82373L9 7V10H15ZM8 12H16H17C17.5523 12 18 12.4477 18 13V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V13C6 12.4477 6.44772 12 7 12H8ZM12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14Z" />
  </Svg>
);

export default Padlock;
