import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const QuestionMark = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path
      d="M10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 10.7571 13.5793 11.4159 12.959 11.7555C12.4745 12.0207 12 12.4477 12 13"
      stroke="#002B54"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47716 2 2 6.47716 2 12C2 17.5229 6.47716 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47716 17.5229 2 12 2ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
    />
  </Svg>
);

export default QuestionMark;
