import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Download = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 24" className={twMerge('stroke-none', className)} {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.559 16.899C11.6914 16.9637 11.8409 17 12 17C12.2209 17 12.4257 16.9274 12.5916 16.8049C12.6335 16.7754 12.6732 16.743 12.7102 16.7079L17.7102 11.7079C18.1002 11.3079 18.1002 10.6779 17.7102 10.2879C17.3102 9.8889 16.6802 9.88889 16.2902 10.2889L13 13.5868V4C13 3.44 12.55 3 12 3C11.44 3 11 3.44 11 4V13.5863L7.71023 10.2889C7.31023 9.88989 6.68023 9.88989 6.29023 10.2899C5.89023 10.6799 5.89023 11.3099 6.29023 11.7099L11.2902 16.7099C11.3671 16.7867 11.4585 16.8508 11.559 16.899ZM21 19C21 20.65 19.65 22 18 22H6C4.34 22 3 20.65 3 19V17C3 16.44 3.44 16 4 16C4.55 16 5 16.44 5 17V19C5 19.55 5.44 20 6 20H18C18.55 20 19 19.55 19 19V17C19 16.44 19.44 16 20 16C20.55 16 21 16.44 21 17V19Z" fill="#002B54" />
  </Svg>
);

export default Download;
