import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Telephone = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9 3C9.36801 3 9.70266 3.20165 9.87662 3.51875L9.92848 3.62861L11.9285 8.62861C12.0956 9.04635 11.9621 9.51864 11.6145 9.78897L11.5145 9.85749L9.841 10.861L9.90374 10.9637C10.7005 12.2288 11.7712 13.2995 13.0363 14.0963L13.138 14.158L14.1425 12.4855C14.374 12.0997 14.8308 11.9202 15.2563 12.0333L15.3714 12.0715L20.3714 14.0715C20.7131 14.2082 20.9489 14.5197 20.9926 14.8787L21 15V19C21 20.6569 19.6569 22 17.9393 21.9982C9.36247 21.4769 2.52306 14.6375 2 6C2 4.40232 3.24892 3.09634 4.82373 3.00509L5 3H9ZM8.323 5H5C4.44772 5 4 5.44772 3.99816 5.93934C4.45806 13.5072 10.4928 19.5419 18 20C18.5128 20 18.9355 19.614 18.9933 19.1166L19 19V15.677L15.419 14.244L14.3575 16.0145C14.1103 16.4264 13.6096 16.5998 13.167 16.443L13.0577 16.3969C10.6887 15.2285 8.77146 13.3113 7.60314 10.9423C7.39065 10.5115 7.516 9.99665 7.88754 9.7094L7.9855 9.64251L9.755 8.58L8.323 5Z" />
  </Svg>

);

export default Telephone;
