import Svg, { SvgProps } from './Svg';

const Star = ({ full, ...rest }: SvgProps & { full?: boolean }) => (
  <Svg
    viewBox="0 0 24 24"
    className={`stroke-2 ${!full ? 'fill-none' : ''}`}
    {...rest}
  >
    <path d="M11.1092 3.4382C11.6142 2.18645 13.3863 2.18645 13.8913 3.4382L15.8286 8.24032C15.8994 8.41575 16.0631 8.53622 16.2516 8.5516L21.1178 8.94866C22.4372 9.05632 22.9804 10.6957 21.9864 11.57L18.2939 14.8177C18.1573 14.9379 18.0967 15.1228 18.1358 15.3006L19.3166 20.6676C19.6116 22.0083 18.0999 23.0081 16.9816 22.2119L12.7903 19.2276C12.6167 19.104 12.3838 19.104 12.2102 19.2276L8.0189 22.2119C6.90065 23.0081 5.38896 22.0083 5.68392 20.6676L6.8647 15.3006C6.9038 15.1228 6.84324 14.9379 6.70658 14.8177L3.01395 11.57C2.01989 10.6958 2.56315 9.05632 3.88258 8.94866L8.74889 8.5516C8.93743 8.53622 9.10114 8.41575 9.17192 8.24032L11.1092 3.4382Z" />
  </Svg>
);

export default Star;
