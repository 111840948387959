import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

type Direction = {
  direction?: 'top' | 'right' | 'bottom' | 'left';
};

const ArrowDouble = ({ className, direction = 'left', ...rest }: SvgProps & Direction) => (
  <Svg
    viewBox="0 0 24 24"
    className={twMerge(
      'stroke-none transition-transform duration-500',
      direction === 'top' && 'rotate-90',
      direction === 'right' && 'rotate-180',
      direction === 'bottom' && '-rotate-90',
      className,
    )}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7071 6.29289C11.3166 5.90237 10.6834 5.90237 10.2929 6.29289L5.29289 11.2929L5.2097 11.3871C4.90468 11.7794 4.93241 12.3466 5.29289 12.7071L10.2929 17.7071L10.3871 17.7903C10.7794 18.0953 11.3466 18.0676 11.7071 17.7071L11.7903 17.6129C12.0953 17.2206 12.0676 16.6534 11.7071 16.2929L7.415 12L11.7071 7.70711L11.7903 7.6129C12.0953 7.22061 12.0676 6.65338 11.7071 6.29289ZM17.7071 6.29289C17.3166 5.90237 16.6834 5.90237 16.2929 6.29289L11.2929 11.2929L11.2097 11.3871C10.9047 11.7794 10.9324 12.3466 11.2929 12.7071L16.2929 17.7071L16.3871 17.7903C16.7794 18.0953 17.3466 18.0676 17.7071 17.7071L17.7903 17.6129C18.0953 17.2206 18.0676 16.6534 17.7071 16.2929L13.415 12L17.7071 7.70711L17.7903 7.6129C18.0953 7.22061 18.0676 6.65338 17.7071 6.29289Z"
    />
  </Svg>
);

export default ArrowDouble;
