import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Refresh = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 24" className={twMerge('stroke-none', className)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9763 21.0411C7.44762 21.0546 3.61427 17.701 3.0257 13.2108C2.95392 12.6632 3.33965 12.1611 3.88725 12.0893C4.43485 12.0175 4.93696 12.4033 5.00874 12.9509C5.46651 16.4433 8.44802 19.0516 11.9703 19.0411C15.4926 19.0305 18.4584 16.4044 18.8953 12.9093C19.3322 9.41425 17.104 6.1389 13.6927 5.26167C10.794 4.51626 7.80832 5.6997 6.17679 8.07989L9.01722 8.08083C9.53005 8.08083 9.95272 8.46687 10.0105 8.96421L10.0172 9.08083C10.0172 9.59367 9.63118 10.0163 9.13384 10.0741L9.01722 10.0808L4.01722 10.0808C3.50438 10.0808 3.08171 9.69479 3.02394 9.19745L3.01722 9.08083L3.01722 4.08083C3.01722 3.52855 3.46493 3.08083 4.01722 3.08083C4.53005 3.08083 4.95273 3.46687 5.01049 3.96421L5.01722 4.08083L5.01648 6.29988C7.18774 3.67799 10.7373 2.4366 14.1908 3.32469C18.5768 4.45255 21.4416 8.66372 20.8799 13.1574C20.3182 17.6511 16.5049 21.0275 11.9763 21.0411Z"
    />
  </Svg>

);

export default Refresh;
