import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Email = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0001 4H5.00011C3.34965 4 2.01048 5.3328 2.00017 6.98083C1.99997 6.99179 1.99995 7.00273 2.00011 7.01367V17C2.00011 18.6569 3.34325 20 5.00011 20H19.0001C20.657 20 22.0001 18.6569 22.0001 17V7.01366C22.0003 7.00273 22.0002 6.99179 22 6.98085C21.9897 5.33281 20.6506 4 19.0001 4ZM19.888 6.53948C19.7214 6.21894 19.3863 6 19.0001 6H5.00011C4.61389 6 4.27881 6.21894 4.11222 6.53948L12.0001 11.797L19.888 6.53948ZM4.00011 8.86852V17C4.00011 17.5523 4.44782 18 5.00011 18H19.0001C19.5524 18 20.0001 17.5523 20.0001 17V8.86852L12.5548 13.8321C12.2562 14.0311 11.8773 14.0532 11.5609 13.8984L11.4454 13.8321L4.00011 8.86852Z"
    />
  </Svg>

);

export default Email;
