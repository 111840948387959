import Svg, { SvgProps } from './Svg';

const RoundedClose = ({ ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className="stroke-none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM10.7071 9.29289L10.6129 9.2097L10.5114 9.14038C10.1275 8.91161 9.62334 8.96245 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L10.585 12L9.29289 13.2929L9.2097 13.3871C8.90468 13.7794 8.93241 14.3466 9.29289 14.7071C9.68342 15.0976 10.3166 15.0976 10.7071 14.7071L12 13.415L13.2929 14.7071L13.3871 14.7903C13.7794 15.0953 14.3466 15.0676 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13.415 12L14.7071 10.7071L14.7903 10.6129C15.0953 10.2206 15.0676 9.65338 14.7071 9.29289C14.3166 8.90237 13.6834 8.90237 13.2929 9.29289L12 10.585L10.7071 9.29289Z"
    />
  </Svg>

);

export default RoundedClose;
