import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Building = ({ className, ...rest }: SvgProps) => (
  <Svg viewBox="0 0 24 24" className={twMerge('stroke-none', className)} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9949 4.82373C19.9037 3.24892 18.5977 2 17 2H7L6.82373 2.00509C5.24892 2.09634 4 3.40232 4 5V20H3L2.88338 20.0067C2.38604 20.0645 2 20.4872 2 21C2 21.5523 2.44772 22 3 22H5H19H21L21.1166 21.9933C21.614 21.9355 22 21.5128 22 21C22 20.4477 21.5523 20 21 20H20V5L19.9949 4.82373ZM18 20V5C18 4.48716 17.614 4.06449 17.1166 4.00673L17 4H7C6.48716 4 6.06449 4.38604 6.00673 4.88338L6 5V20H18ZM11 8C11 7.44772 10.5523 7 10 7H9L8.88338 7.00673C8.38604 7.06449 8 7.48716 8 8C8 8.55228 8.44772 9 9 9H10L10.1166 8.99327C10.614 8.93551 11 8.51284 11 8ZM10 11C10.5523 11 11 11.4477 11 12C11 12.5128 10.614 12.9355 10.1166 12.9933L10 13H9C8.44772 13 8 12.5523 8 12C8 11.4872 8.38604 11.0645 8.88338 11.0067L9 11H10ZM11 16C11 15.4477 10.5523 15 10 15H9L8.88338 15.0067C8.38604 15.0645 8 15.4872 8 16C8 16.5523 8.44772 17 9 17H10L10.1166 16.9933C10.614 16.9355 11 16.5128 11 16ZM15 7C15.5523 7 16 7.44772 16 8C16 8.51284 15.614 8.93551 15.1166 8.99327L15 9H14C13.4477 9 13 8.55228 13 8C13 7.48716 13.386 7.06449 13.8834 7.00673L14 7H15ZM16 12C16 11.4477 15.5523 11 15 11H14L13.8834 11.0067C13.386 11.0645 13 11.4872 13 12C13 12.5523 13.4477 13 14 13H15L15.1166 12.9933C15.614 12.9355 16 12.5128 16 12ZM15 15C15.5523 15 16 15.4477 16 16C16 16.5128 15.614 16.9355 15.1166 16.9933L15 17H14C13.4477 17 13 16.5523 13 16C13 15.4872 13.386 15.0645 13.8834 15.0067L14 15H15Z"
    />
  </Svg>
);

export default Building;
