import { twMerge } from 'tailwind-merge';
import Svg, { SvgProps } from './Svg';

const Pen = ({ className, ...rest }: SvgProps) => (
  <Svg {...rest} viewBox="0 0 24 24" className={twMerge('stroke-none', className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7929 4.79289C15.288 3.2978 17.712 3.2978 19.2071 4.79289C20.6506 6.23643 20.7004 8.54594 19.3564 10.0492L19.2071 10.2071L18.2071 11.2071L8.70711 20.7071C8.55083 20.8634 8.34815 20.9626 8.13144 20.9913L8 21H4C3.48716 21 3.06449 20.614 3.00673 20.1166L3 20V16C3 15.779 3.07316 15.5655 3.20608 15.392L3.29289 15.2929L12.7927 5.79303C12.7928 5.79299 12.7928 5.79294 12.7929 5.79289C12.7929 5.79284 12.793 5.79279 12.793 5.79275L13.7929 4.79289ZM13.5 7.91419L5 16.414V18.999H7.585L16.0856 10.4998L13.5 7.91419ZM17.5 9.08576L14.9142 6.49999L15.2071 6.2071L15.3301 6.09471C16.0484 5.49526 17.1185 5.53273 17.7929 6.2071L17.9053 6.33007C18.5047 7.04841 18.4673 8.11851 17.7929 8.79289L17.5 9.08576Z" />
  </Svg>
);

export default Pen;
